<template>
  <PageWithLayout>
    <Board
      :boardData="viewModel.boardData"
      :searchParams.sync="viewModel.searchParams"
      :dataList="viewModel.dataList"
      :paginationData="viewModel.paginationData"
      @onClickRow="viewModel.onClickRow"
      @onSearch="viewModel.onSearch()">
      <template v-slot:headRight>
        <Button
          btnSize="small"
          btnStyle="primary"
          text="등록하기"
          routeName="ADMIN_MEMBER_REGISTER"/>
      </template>
    </Board>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@lemontree-ai/lemontree-admin-common-front/components/layout/PageWithLayout';
import Board from '@lemontree-ai/lemontree-admin-common-front/components/common/board/Board';
import Button from '@lemontree-ai/lemontree-admin-common-front/components/common/button/Button';
// viewModel
import MemberListViewModel from '@/views/admin/member/viewModel/MemberListViewModel'

export default {
  name:'MemberList',
  components:{
    PageWithLayout,
    Board,
    Button,
  },
  beforeMount(){
    this.viewModel.init();
  },
  data(){
    return{
      viewModel: new MemberListViewModel(),
    }
  },
}
</script>